<template>
    <div id="gjs"></div>
</template>

<script>
import "grapesjs/dist/css/grapes.min.css";
import grapesjs from "grapesjs";
import 'grapesjs-preset-webpage';
import gpjTuiImage from 'grapesjs-tui-image-editor';
import gpjCustomCode from 'grapesjs-custom-code';
import gpjForms from 'grapesjs-plugin-forms';
//import gpjExport from 'grapesjs-plugin-export';
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import { configStyleManager, customFields } from "../../conf/editor/confEditor";
import { SetupEditor } from "../../conf/editor/setup";
import { toDataFile } from "@/js/utils";
import html2canvas from 'html2canvas';
import es from 'grapesjs/locale/es';
import { B64_THUMBNAIL_ERROR } from '../../js/constants';

export default {
  data: () => ({
      editor: null,
      template: null,
      imgModelsOrigin: null,
      imgModels: null,
      interval: null,
  }),
  methods: {
    ...mapActions("templates", {
      getTemplate: "findById",
      saveTemplate: "save",
      ping: "ping"
    }),
    ...mapActions("images", {
      getImages: "find",
      saveImage: "save",
      deleteImage: "destroy"
    }),
    ...mapActions("notifications", ["success", "info", "error"]),
    ...mapGetters(["accessToken"]),
    async save(template) {
      var node = this.editor.Canvas.getFrame().view.wrapper.$el[0];
      if(this.isValidTemplate()){
        this.editor.stopCommand("sw-visibility");
        this.editor.select();
        try {
          window.frames[0].scrollTo(0, 0);
          let canvas = await html2canvas(node, { logging: false, letterRendering: 1, allowTaint: true, useCORS: true});
          this.editor.runCommand("sw-visibility");
          let imgThumbnailB64 = canvas.toDataURL('image/jpeg', 0.70);
          template.srcThumbnail = (imgThumbnailB64 == 'data:,') ? B64_THUMBNAIL_ERROR : imgThumbnailB64;    
          let idSavedTemplate = await this.saveTemplate(template);
          if(!template.id){
            template.id = JSON.parse(idSavedTemplate).id;
          }
          this.$store.dispatch("setTitle", this.$t("views.templates.edittemplate.editParam", { name: template.name }));
          this.success(this.$t("views.templates.edittemplate.editor.templateSaved"));
        } catch (error) { 
          this.error(error.message)
        }
      } 
    },
    isValidTemplate(){
      let isValid = true;
      let formMetadata = {};
      let compType;

      /*if(!this.template.expirationDays && !this.template.expirationDate && !this.$can('manage', 'template')){
        this.editor.runCommand("editDataTemplate");
        this.error(this.$t("views.templates.edittemplate.editor.errorTemplateWithoutExpiration"))    
        return;
      }*/
      
      this.editor.DomComponents.getWrapper().onAll(comp => {
        compType = comp.attributes.type;
        if((compType == 'input' || compType == 'select' || compType == 'radio' || compType == 'checkbox' || compType == 'textarea') && isValid){
          formMetadata[comp.attributes.attributes.name] = comp.attributes.attributes.type ? comp.attributes.attributes.type : comp.attributes.type;
          if(comp.attributes.components.models.length > 0) {
            formMetadata[comp.attributes.attributes.name] = [];
            comp.attributes.components.models.forEach(x => {
              let key = x.attributes.attributes.value;
              let val = x.attributes.content;
              let obj = {};
              obj[key] = val;
              formMetadata[comp.attributes.attributes.name].push(obj);
            });
          }  
          if(!comp.attributes.attributes.name){
            isValid = false;
            this.editor.select(comp)
            this.error({ msg: this.$t("views.templates.edittemplate.editor.errorFormWithoutAttributeName"), timeout: 10000});
            return;
          }
        }

        if(compType == 'chat' && isValid && !comp.attributes.attributes.flujo){
          isValid = false;
          this.editor.select(comp)
          this.error({ msg: this.$t("views.templates.edittemplate.editor.errorChatWithoutAttributeFlow"), timeout: 10000});
          return;
        }

      });
      this.template.formMetadata = JSON.stringify(formMetadata);
      return isValid;
    },
    prueba(filtro){
      const assetManager = this.editor.AssetManager;
      this.editor.AssetManager.render(this.editor.AssetManager.getAll().filter(asset => asset.get('category') == filtro));
    }
  },
  async beforeMount() {
    const id = this.$route.params.id;

    if (id) {
      try {
        let template = await this.getTemplate(id);
        if (!this.$can("manage", "template") && template.default) {
          this.$router.push("/");
        }
        this.template = template;
        this.editor.setComponents(this.template.html);
        this.editor.setStyle(this.template.css);
        this.$store.dispatch("setTitle", this.$t("views.templates.edittemplate.editParam", { name: template.name }));
      } catch (error) {
        this.error(error.message)
      }
    } else {
      this.template = {};
      this.$store.dispatch("setTitle",this.$t("views.templates.edittemplate.new"));
    }
  },
  async mounted() {

    var that = this;

    this.editor = grapesjs.init({
        avoidInlineStyle: 1,
        height: '93.6%',
        container : '#gjs',
        fromElement: 1,
        showOffsets: 1,
        noticeOnUnload: 0,
        allowScripts: 1,
        storageManager: { autoload: 0 },
        colorPicker: {
          appendTo: 'parent',
          offset: { top: 26, left: -166, },
        },
        i18n: {
          locale: 'es',
          messages: { es },
        },
        canvas: {
          scripts: [
            'https://code.jquery.com/jquery-3.4.1.slim.min.js',
            'https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js',
            'https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js'
          ],
          styles: [
            'https://fonts.googleapis.com/css?family=Abril+Fatface|Amatic+SC|Anton|Bitter|Caveat|Exo|Fredoka+One|Indie+Flower|Kanit|Lobster|Montserrat|Open+Sans+Condensed:300|PT+Sans+Narrow|Pacifico|Poppins|Raleway|Roboto|Shadows+Into+Light|Source+Code+Pro|Spicy+Rice|Teko&display=swap'
          ]
        },
        protectedCss: '.row {margin-left: 0px; margin-right: 0px;}',
        assetManager: {
          modalTitle: that.$t("views.templates.edittemplate.editor.modalTitle"),
          uploadText: that.$t("views.templates.edittemplate.editor.uploadText"),
          multiUpload: true,
          addBtnText: that.$t("views.templates.edittemplate.editor.addBtnText"),
          uploadFile: async (e) => {
            var files = e.dataTransfer ? e.dataTransfer.files : e.target.files;

            for (var i = 0; i < files.length; i++) {
              let file = files[i];

              for (let i in that.imgModelsOrigin) {
                let img = that.imgModelsOrigin[i];
                if (_.includes(img.attributes.src, file.name)) {
                  that.info(that.$t("views.templates.edittemplate.editor.imgExists"));
                  return;
                }
              }
              try {
                let dataB64 = await toDataFile(file);
                let imageSaved = await this.saveImage({ name: file.name, data: dataB64 });
                this.editor.AssetManager.add(imageSaved);
                this.imgModelsOrigin = _.clone(that.imgModels);
                this.success(that.$t("views.templates.edittemplate.editor.imgUploaded"));
              } catch (error) {
                this.error(error.message)
              }
            }
          }
        },
        styleManager: { clearProperties: 1 },
        plugins: [
          'gjs-preset-webpage',
          gpjForms,
          gpjCustomCode,
          gpjTuiImage,
        ],
        pluginsOpts: {
          'gjs-preset-webpage': {
            modalImportTitle: 'Importar Plantilla',
            modalImportLabel: '<div style="margin-bottom: 10px; font-size: 13px;">Copia aquí tu código HTML y CSS y dale a "Import"</div>',
            aviaryOpts: false,
            blocksBasicOpts: { flexGrid: 1 },
            customStyleManager: configStyleManager
          }
        },
      });

    new SetupEditor(this).setupEditor(); 
    const assetManager = this.editor.AssetManager;
    window.editorLandingsAssetsGjs = assetManager;

    const pn = this.editor.Panels;   
    pn.getButton('options', 'sw-visibility').set('active', 1);

    this.editor.on('load', function() {
      const $ = grapesjs.$;
      // Load and show settings and style manager
      const openTmBtn = pn.getButton('views', 'open-tm');
      openTmBtn && openTmBtn.set('active', 1);
      const openSm = pn.getButton('views', 'open-sm');
      openSm && openSm.set('active', 1);

      // Add Settings Sector
      const traitsSector = $('<div class="gjs-sm-sector no-select">'+
        '<div class="gjs-sm-title"><span class="icon-settings fa fa-cog"></span> Settings</div>' +
        '<div class="gjs-sm-properties" style="display: none;"></div></div>');
      const traitsProps = traitsSector.find('.gjs-sm-properties');
      traitsProps.append($('.gjs-trt-traits'));
      $('.gjs-sm-sectors').before(traitsSector);
      const traitStyle = traitsProps.get(0).style;
      traitStyle.display = 'block';

      // Open block manager
      const openBlocksBtn = that.editor.Panels.getButton('views', 'open-blocks');
      openBlocksBtn && openBlocksBtn.set('active', 1);
      pn.removeButton('views', 'open-tm');
    });  
   

    this.editor.on("stop:preview", () => {
      const isActive = pn.getButton('options', 'sw-visibility').get('active');
      if(!isActive){
        this.editor.stopCommand("sw-visibility");
      }
    });

    const head = this.editor.Canvas.getDocument().head;
    head.insertAdjacentHTML('beforeend', `<link href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous">`);

    try {
      let images = await this.getImages();
      assetManager.add(images);
      const assets = assetManager.getAll();      
      this.imgModelsOrigin = _.clone(assets.models);
      this.imgModels = assets.models;
      assetManager.render();
      assetManager.getContainer().insertAdjacentHTML('afterbegin', '<div><button type="button" onClick="window.editorLandingsAssetsGjs.render(window.editorLandingsAssetsGjs.getAll().filter(asset => asset.get(\'category\') == \'L\'))">Logo</button></div>');
      assetManager.getContainer().insertAdjacentHTML('afterbegin', '<div><button type="button" onClick="window.editorLandingsAssetsGjs.render()">General</button></div>');
      assetManager.getContainer().insertAdjacentHTML('afterbegin', '<div><button type="button" onClick="window.editorLandingsAssetsGjs.render(window.editorLandingsAssetsGjs.getAll().filter(asset => asset.get(\'category\') == \'F\'))">Firma</button></div>');
    } catch (error) {
      this.error(error.message);
    }

    this.interval = setInterval(async () => {
      await this.ping();
    }, 300000);
  },
  destroyed() {
    this.editor = null;
    clearInterval(this.interval);
  },
  watch: {
    imgModels: function(modified) {
      var that = this;
      let sortedModified = _.sortBy(modified);
      let sortedOrigin = _.sortBy(this.imgModelsOrigin);

      if (!_.isEqual(sortedModified, sortedOrigin)) {
        let itemsModified = _.difference(sortedOrigin, sortedModified);

        for (let i in itemsModified) {
          let img = itemsModified[i];
          if (!img.attributes.default || this.$can("manage", "template")) {
            this.deleteImage({
              id: img.attributes.id,
              src: img.attributes.src
            }).then(image =>{
              that.success(that.$t("views.templates.edittemplate.editor.imgDeleted"));
            }).catch(error => {
              that.error(error.message);
            });
            this.imgModelsOrigin = _.clone(modified);
          } else {
            this.info(that.$t("views.templates.edittemplate.editor.imgCantDeleted"));
            if (!this.editor.AssetManager.get(img.attributes.src)) {
              this.editor.AssetManager.add({
                src: img.attributes.src,
                category: img.attributes.category,
                default: img.attributes.default
              });
            }
          }
        }
      }
    }
  }
};
</script>

<style lang="scss">
$bgcolor: #004b87;

#gjs {
  margin-left: -0.85em;
  margin-top: -0.88em;
  position: absolute;
}

.gjs-am-preview-bg {
  background-repeat: initial
}

.gjs-am-preview-cont{
   background-color: transparent;
}

.gjs-layer-title div span {
  padding-bottom: 10px;
  padding-left: -50px
}

/* Primary color for the background */
.gjs-one-bg {
  background-color: $bgcolor;
}

/* Secondary color for the text color */
.gjs-two-color {
  color: rgba(255, 255, 255, 0.7);
}

/* Quaternary color for the text color */
.gjs-four-color,
.gjs-four-color-h:hover {
  color: white;
}
//Caja código importar
.CodeMirror-scroll {
  background-color: #fff;
}
//Cajas de código exportar
.cm-s-hopscotch .CodeMirror-gutters {
  background-color: #d5d3d5;
}
//Color cuadro preview
.gjs-off-prv {
  background-color: $bgcolor;
}
.CodeMirror-sizer {
  margin-left: 50px;
  padding-right: 0px;
  padding-bottom: 0px;
  min-height: 28px;
}
.clientFields {
  background-color: $bgcolor;
  font-size: larger;
}
.gjs-input-holder > select:focus {
  background-color: inherit;
}

.gjs-select option,
.gjs-clm-select option,
.gjs-sm-select option,
.gjs-fields option,
.gjs-sm-unit option,
.gjs-field-select option {
  background-color: $bgcolor;
  color: #fff;
}
.gjs-layer-caret {
  font-size: 0.7rem;
  top: 9px;
  padding: 0px;
}

.gjs-three-bg {
  background-color: #3a526f;
}

.gjs-mdl-dialog {
  max-width: 1200px;
}
.gjs-am-file-uploader {
  width: 20%;
}

.gjs-am-assets {
  height: 462px;
}

.gjs-am-assets-cont {
  width: 79%;
  height: 100%;
}

.gjs-am-file-uploader>form #gjs-am-uploadFile {
  padding: 246px 10px;
}

.gjs-am-file-uploader #gjs-am-title {
  padding: 246px 10px;
}

.gjs-am-asset {
  border: 1px solid rgba(0,0,0,0.2);
  padding: 5px;
  width: 25%;
}

//Boton TUIeditor
div > button{
  color: #1E1E1E; 
}

</style>